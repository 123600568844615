import type { I18nDefinition } from '@backmarket/nuxt-module-i18n/types/i18n'

import translations from './AnimatedProductCheckpoints.translations'
import type { AnimatedProductCategory } from './types'

type Image = {
  src: string
  alt: string
  width: number
  height: number
}
type AnimatedProductCheckpoint = {
  text: I18nDefinition
  icon: string
  image?: Image
  description: { [key in AnimatedProductCategory]: I18nDefinition }
}

type AnimatedProductCategoryData = Record<
  AnimatedProductCategory,
  {
    checkpoints: AnimatedProductCheckpoint[]
    image: Image
  }
>
export const categories: AnimatedProductCategoryData = {
  smartphones: {
    checkpoints: [
      {
        icon: 'IconTouch',
        text: translations.buttons,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/buttons.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.buttonsDescription,
          tablets: translations.buttonsDescription,
          laptops: translations.buttonsDescription,
          consoles: translations.buttonsDescription,
          smartwatches: translations.buttonsDescription,
        },
      },
      {
        icon: 'IconSim',
        text: translations.sim,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/sim_and_memory_card_reader.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.simDescriptionSmartphones,
          tablets: translations.simDescriptionTablets,
          laptops: translations.simDescriptionLaptops,
          consoles: translations.simDescriptionGamingConsoles,
          smartwatches: translations.simDescriptionSmartwatches,
        },
      },
      {
        icon: 'IconFactory',
        text: translations.factoryReset,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/factory_reset_completed.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.factoryResetDescription,
          tablets: translations.factoryResetDescription,
          laptops: translations.factoryResetDescription,
          consoles: translations.factoryResetDescription,
          smartwatches: translations.factoryResetDescription,
        },
      },
      {
        icon: 'IconPowerAdapterApple',
        text: translations.charger,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/accessories.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.chargerDescriptionSmartphones,
          tablets: translations.chargerDescriptionTablets,
          laptops: translations.chargerDescriptionLaptops,
          consoles: translations.chargerDescriptionGamingConsoles,
          smartwatches: translations.chargerDescriptionSmartwatches,
        },
      },
      {
        icon: 'IconLockUnlocked',
        text: translations.unlocked,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/unlocked_from_any_previous_protection_systems.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.unlockedDescription,
          tablets: translations.unlockedDescription,
          laptops: translations.unlockedDescription,
          consoles: translations.unlockedDescription,
          smartwatches: translations.unlockedDescription,
        },
      },
      {
        icon: 'IconLocationPinOutlined',
        text: translations.gps,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/gps_and_positioning_system.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.gpsDescription,
          tablets: translations.gpsDescription,
          laptops: translations.gpsDescription,
          consoles: translations.gpsDescription,
          smartwatches: translations.gpsDescription,
        },
      },
      {
        icon: 'IconCamera',
        text: translations.cameras,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/all_cameras.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.camerasDescription,
          tablets: translations.camerasDescription,
          laptops: translations.camerasDescription,
          consoles: translations.camerasDescription,
          smartwatches: translations.camerasDescription,
        },
      },
      {
        icon: 'IconTouch',
        text: translations.externalSensor,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/external_sensors.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.externalSensorDescription,
          tablets: translations.externalSensorDescription,
          laptops: translations.externalSensorDescription,
          consoles: translations.externalSensorDescription,
          smartwatches: translations.externalSensorDescription,
        },
      },
      {
        icon: 'IconCheckInCircle',
        text: translations.biometricSensors,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/biometric_sensors.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.biometricSensorsDescription,
          tablets: translations.biometricSensorsDescription,
          laptops: translations.biometricSensorsDescription,
          consoles: translations.biometricSensorsDescription,
          smartwatches: translations.biometricSensorsDescription,
        },
      },
      {
        icon: 'IconWifi',
        text: translations.wifi,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/wi-fi_and_bluetooth.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.wifiDescription,
          tablets: translations.wifiDescription,
          laptops: translations.wifiDescription,
          consoles: translations.wifiDescription,
          smartwatches: translations.wifiDescription,
        },
      },
      {
        icon: 'IconCrosshair',
        text: translations.imei,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/imei_code_and_serial_number.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.imeiDescription,
          tablets: translations.imeiDescription,
          laptops: translations.imeiDescription,
          consoles: translations.imeiDescription,
          smartwatches: translations.imeiDescription,
        },
      },
      {
        icon: 'IconWaterdrop',
        text: translations.oxidation,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/oxidation_indicator.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.oxidationDescription,
          tablets: translations.oxidationDescription,
          laptops: translations.oxidationDescription,
          consoles: translations.oxidationDescription,
          smartwatches: translations.oxidationDescription,
        },
      },
      {
        icon: 'IconLightbulb',
        text: translations.flash,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/flash_and_indicator_lights.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.flashDescription,
          tablets: translations.flashDescription,
          laptops: translations.flashDescription,
          consoles: translations.flashDescription,
          smartwatches: translations.flashDescription,
        },
      },
      {
        icon: 'IconHeadset',
        text: translations.microphone,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/mic_and_speakers.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.microphoneDescription,
          tablets: translations.microphoneDescription,
          laptops: translations.microphoneDescription,
          consoles: translations.microphoneDescription,
          smartwatches: translations.microphoneDescription,
        },
      },
      {
        icon: 'IconChargingWire',
        text: translations.ports,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/input-output_port_connections.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.portsDescription,
          tablets: translations.portsDescription,
          laptops: translations.portsDescription,
          consoles: translations.portsDescription,
          smartwatches: translations.portsDescription,
        },
      },
      {
        icon: 'IconWifi',
        text: translations.carrier,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/features_specific_to_the_type_of_device.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.carrierDescription,
          tablets: translations.carrierDescription,
          laptops: translations.carrierDescription,
          consoles: translations.carrierDescription,
          smartwatches: translations.carrierDescription,
        },
      },
      {
        icon: 'IconBattery',
        text: translations.battery,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/battery_functionality.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.batteryDescription,
          tablets: translations.batteryDescription,
          laptops: translations.batteryDescription,
          consoles: translations.batteryDescription,
          smartwatches: translations.batteryDescription,
        },
      },
      {
        icon: 'IconRepairHome',
        text: translations.mechanical,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/mechanical_parts.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.mechanicalDescription,
          tablets: translations.mechanicalDescription,
          laptops: translations.mechanicalDescription,
          consoles: translations.mechanicalDescription,
          smartwatches: translations.mechanicalDescription,
        },
      },
      {
        icon: 'IconExport',
        text: translations.compatiblePart,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/compatible_parts.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.compatiblePartDescription,
          tablets: translations.compatiblePartDescription,
          laptops: translations.compatiblePartDescription,
          consoles: translations.compatiblePartDescription,
          smartwatches: translations.compatiblePartDescription,
        },
      },
      {
        icon: 'IconSmartphone',
        text: translations.screen,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/screen_quality.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.screenDescriptionSmartphones,
          tablets: translations.screenDescriptionTablets,
          laptops: translations.screenDescriptionLaptops,
          consoles: translations.screenDescriptionGamingConsoles,
          smartwatches: translations.screenDescriptionSmartwatches,
        },
      },
      {
        icon: 'IconListView2',
        text: translations.features,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartphones/imei_code_and_serial_number.webp',
          alt: 'Iphone 13',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.featuresDescription,
          tablets: translations.featuresDescription,
          laptops: translations.featuresDescription,
          consoles: translations.featuresDescription,
          smartwatches: translations.featuresDescription,
        },
      },
    ],

    image: {
      src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/categories/smartphones.webp',
      alt: 'Iphone',
      height: 80,
      width: 80,
    },
  },
  tablets: {
    checkpoints: [
      {
        icon: 'IconTouch',
        text: translations.buttons,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/buttons.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.buttonsDescription,
          tablets: translations.buttonsDescription,
          laptops: translations.buttonsDescription,
          consoles: translations.buttonsDescription,
          smartwatches: translations.buttonsDescription,
        },
      },
      {
        icon: 'IconSim',
        text: translations.sim,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/sim_and_memory_card_reader.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.simDescriptionSmartphones,
          tablets: translations.simDescriptionTablets,
          laptops: translations.simDescriptionLaptops,
          consoles: translations.simDescriptionGamingConsoles,
          smartwatches: translations.simDescriptionSmartwatches,
        },
      },
      {
        icon: 'IconFactory',
        text: translations.factoryReset,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/factory_reset_completed.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.factoryResetDescription,
          tablets: translations.factoryResetDescription,
          laptops: translations.factoryResetDescription,
          consoles: translations.factoryResetDescription,
          smartwatches: translations.factoryResetDescription,
        },
      },
      {
        icon: 'IconPowerAdapterApple',
        text: translations.charger,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/accessories.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.chargerDescriptionSmartphones,
          tablets: translations.chargerDescriptionTablets,
          laptops: translations.chargerDescriptionLaptops,
          consoles: translations.chargerDescriptionGamingConsoles,
          smartwatches: translations.chargerDescriptionSmartwatches,
        },
      },
      {
        icon: 'IconLockUnlocked',
        text: translations.unlocked,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/unlocked_from_any_previous_protection_systems.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.unlockedDescription,
          tablets: translations.unlockedDescription,
          laptops: translations.unlockedDescription,
          consoles: translations.unlockedDescription,
          smartwatches: translations.unlockedDescription,
        },
      },
      {
        icon: 'IconLocationPinOutlined',
        text: translations.gps,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/gps_and_positioning_system.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.gpsDescription,
          tablets: translations.gpsDescription,
          laptops: translations.gpsDescription,
          consoles: translations.gpsDescription,
          smartwatches: translations.gpsDescription,
        },
      },
      {
        icon: 'IconCamera',
        text: translations.cameras,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/all_cameras.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.camerasDescription,
          tablets: translations.camerasDescription,
          laptops: translations.camerasDescription,
          consoles: translations.camerasDescription,
          smartwatches: translations.camerasDescription,
        },
      },
      {
        icon: 'IconTouch',
        text: translations.externalSensor,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/external_sensors.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.externalSensorDescription,
          tablets: translations.externalSensorDescription,
          laptops: translations.externalSensorDescription,
          consoles: translations.externalSensorDescription,
          smartwatches: translations.externalSensorDescription,
        },
      },
      {
        icon: 'IconCheckInCircle',
        text: translations.biometricSensors,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/biometric_sensors.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.biometricSensorsDescription,
          tablets: translations.biometricSensorsDescription,
          laptops: translations.biometricSensorsDescription,
          consoles: translations.biometricSensorsDescription,
          smartwatches: translations.biometricSensorsDescription,
        },
      },
      {
        icon: 'IconWifi',
        text: translations.wifi,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/wi-fi_and_bluetooth.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.wifiDescription,
          tablets: translations.wifiDescription,
          laptops: translations.wifiDescription,
          consoles: translations.wifiDescription,
          smartwatches: translations.wifiDescription,
        },
      },
      {
        icon: 'IconCrosshair',
        text: translations.imei,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/imei_code_and_serial_number.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.imeiDescription,
          tablets: translations.imeiDescription,
          laptops: translations.imeiDescription,
          consoles: translations.imeiDescription,
          smartwatches: translations.imeiDescription,
        },
      },
      {
        icon: 'IconWaterdrop',
        text: translations.oxidation,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/oxidation_indicator.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.oxidationDescription,
          tablets: translations.oxidationDescription,
          laptops: translations.oxidationDescription,
          consoles: translations.oxidationDescription,
          smartwatches: translations.oxidationDescription,
        },
      },
      {
        icon: 'IconLightbulb',
        text: translations.flash,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/flash_and_indicator_lights.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.flashDescription,
          tablets: translations.flashDescription,
          laptops: translations.flashDescription,
          consoles: translations.flashDescription,
          smartwatches: translations.flashDescription,
        },
      },
      {
        icon: 'IconHeadset',
        text: translations.microphone,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/mic_and_speakers.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.microphoneDescription,
          tablets: translations.microphoneDescription,
          laptops: translations.microphoneDescription,
          consoles: translations.microphoneDescription,
          smartwatches: translations.microphoneDescription,
        },
      },
      {
        icon: 'IconChargingWire',
        text: translations.ports,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/input-output_port_connections.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.portsDescription,
          tablets: translations.portsDescription,
          laptops: translations.portsDescription,
          consoles: translations.portsDescription,
          smartwatches: translations.portsDescription,
        },
      },
      {
        icon: 'IconWifi',
        text: translations.carrier,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/unlocked_from_any_previous_protection_systems.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.carrierDescription,
          tablets: translations.carrierDescription,
          laptops: translations.carrierDescription,
          consoles: translations.carrierDescription,
          smartwatches: translations.carrierDescription,
        },
      },
      {
        icon: 'IconBattery',
        text: translations.battery,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/battery_functionality.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.batteryDescription,
          tablets: translations.batteryDescription,
          laptops: translations.batteryDescription,
          consoles: translations.batteryDescription,
          smartwatches: translations.batteryDescription,
        },
      },
      {
        icon: 'IconRepairHome',
        text: translations.mechanical,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/mechanical_parts.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.mechanicalDescription,
          tablets: translations.mechanicalDescription,
          laptops: translations.mechanicalDescription,
          consoles: translations.mechanicalDescription,
          smartwatches: translations.mechanicalDescription,
        },
      },
      {
        icon: 'IconExport',
        text: translations.compatiblePart,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/compatible_parts.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.compatiblePartDescription,
          tablets: translations.compatiblePartDescription,
          laptops: translations.compatiblePartDescription,
          consoles: translations.compatiblePartDescription,
          smartwatches: translations.compatiblePartDescription,
        },
      },
      {
        icon: 'IconSmartphone',
        text: translations.screen,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/screen_quality.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.screenDescriptionSmartphones,
          tablets: translations.screenDescriptionTablets,
          laptops: translations.screenDescriptionLaptops,
          consoles: translations.screenDescriptionGamingConsoles,
          smartwatches: translations.screenDescriptionSmartwatches,
        },
      },
      {
        icon: 'IconListView2',
        text: translations.features,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/tablets/imei_code_and_serial_number.webp',
          alt: 'Ipad',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.featuresDescription,
          tablets: translations.featuresDescription,
          laptops: translations.featuresDescription,
          consoles: translations.featuresDescription,
          smartwatches: translations.featuresDescription,
        },
      },
    ],

    image: {
      src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/categories/tablets.webp',
      alt: 'Tablet',
      height: 80,
      width: 80,
    },
  },
  consoles: {
    checkpoints: [
      {
        icon: 'IconTouch',
        text: translations.buttons,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/consoles/buttons.webp',
          alt: 'Playstation 4',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.buttonsDescription,
          tablets: translations.buttonsDescription,
          laptops: translations.buttonsDescription,
          consoles: translations.buttonsDescription,
          smartwatches: translations.buttonsDescription,
        },
      },
      {
        icon: 'IconSim',
        text: translations.sim,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/consoles/sim_and_memory_card_reader.webp',
          alt: 'Playstation 4',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.simDescriptionSmartphones,
          tablets: translations.simDescriptionTablets,
          laptops: translations.simDescriptionLaptops,
          consoles: translations.simDescriptionGamingConsoles,
          smartwatches: translations.simDescriptionSmartwatches,
        },
      },
      {
        icon: 'IconFactory',
        text: translations.factoryReset,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/consoles/factory_reset_completed.webp',
          alt: 'Playstation 4',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.factoryResetDescription,
          tablets: translations.factoryResetDescription,
          laptops: translations.factoryResetDescription,
          consoles: translations.factoryResetDescription,
          smartwatches: translations.factoryResetDescription,
        },
      },
      {
        icon: 'IconPowerAdapterApple',
        text: translations.charger,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/consoles/accessories.webp',
          alt: 'Playstation 4',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.chargerDescriptionSmartphones,
          tablets: translations.chargerDescriptionTablets,
          laptops: translations.chargerDescriptionLaptops,
          consoles: translations.chargerDescriptionGamingConsoles,
          smartwatches: translations.chargerDescriptionSmartwatches,
        },
      },
      {
        icon: 'IconLockUnlocked',
        text: translations.unlocked,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/consoles/unlocked_from_any_previous_protection_systems.webp',
          alt: 'Playstation 4',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.unlockedDescription,
          tablets: translations.unlockedDescription,
          laptops: translations.unlockedDescription,
          consoles: translations.unlockedDescription,
          smartwatches: translations.unlockedDescription,
        },
      },
      {
        icon: 'IconWifi',
        text: translations.wifi,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/consoles/wi-fi_and_bluetooth.webp',
          alt: 'Playstation 4',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.wifiDescription,
          tablets: translations.wifiDescription,
          laptops: translations.wifiDescription,
          consoles: translations.wifiDescription,
          smartwatches: translations.wifiDescription,
        },
      },
      {
        icon: 'IconCrosshair',
        text: translations.imei,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/consoles/imei_code_and_serial_number.webp',
          alt: 'Playstation 4',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.imeiDescription,
          tablets: translations.imeiDescription,
          laptops: translations.imeiDescription,
          consoles: translations.imeiDescription,
          smartwatches: translations.imeiDescription,
        },
      },
      {
        icon: 'IconWaterdrop',
        text: translations.oxidation,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/consoles/oxidation_indicator.webp',
          alt: 'Playstation 4',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.oxidationDescription,
          tablets: translations.oxidationDescription,
          laptops: translations.oxidationDescription,
          consoles: translations.oxidationDescription,
          smartwatches: translations.oxidationDescription,
        },
      },
      {
        icon: 'IconLightbulb',
        text: translations.flash,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/consoles/flash_and_indicator_lights.webp',
          alt: 'Playstation 4',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.flashDescription,
          tablets: translations.flashDescription,
          laptops: translations.flashDescription,
          consoles: translations.flashDescription,
          smartwatches: translations.flashDescription,
        },
      },
      {
        icon: 'IconHeadset',
        text: translations.microphone,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/consoles/mic_and_speakers.webp',
          alt: 'Playstation 4',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.microphoneDescription,
          tablets: translations.microphoneDescription,
          laptops: translations.microphoneDescription,
          consoles: translations.microphoneDescription,
          smartwatches: translations.microphoneDescription,
        },
      },
      {
        icon: 'IconChargingWire',
        text: translations.ports,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/consoles/input-output_port_connections.webp',
          alt: 'Playstation 4',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.portsDescription,
          tablets: translations.portsDescription,
          laptops: translations.portsDescription,
          consoles: translations.portsDescription,
          smartwatches: translations.portsDescription,
        },
      },
      {
        icon: 'IconRepairHome',
        text: translations.mechanical,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/consoles/mechanical_parts.webp',
          alt: 'Playstation 4',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.mechanicalDescription,
          tablets: translations.mechanicalDescription,
          laptops: translations.mechanicalDescription,
          consoles: translations.mechanicalDescription,
          smartwatches: translations.mechanicalDescription,
        },
      },
      {
        icon: 'IconExport',
        text: translations.compatiblePart,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/consoles/compatible_parts.webp',
          alt: 'Playstation 4',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.compatiblePartDescription,
          tablets: translations.compatiblePartDescription,
          laptops: translations.compatiblePartDescription,
          consoles: translations.compatiblePartDescription,
          smartwatches: translations.compatiblePartDescription,
        },
      },
      {
        icon: 'IconSmartphone',
        text: translations.screen,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/consoles/screen_quality.webp',
          alt: 'Playstation 4',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.screenDescriptionSmartphones,
          tablets: translations.screenDescriptionTablets,
          laptops: translations.screenDescriptionLaptops,
          consoles: translations.screenDescriptionGamingConsoles,
          smartwatches: translations.screenDescriptionSmartwatches,
        },
      },
      {
        icon: 'IconPerformance',
        text: translations.cooling,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/consoles/cooling_elements.webp',
          alt: 'Playstation 4',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.coolingDescription,
          tablets: translations.coolingDescription,
          laptops: translations.coolingDescription,
          consoles: translations.coolingDescription,
          smartwatches: translations.coolingDescription,
        },
      },
      {
        icon: 'IconSwap',
        text: translations.subassembly,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/consoles/sub-assembly_elements.webp',
          alt: 'Playstation 4',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.subassemblyDescription,
          tablets: translations.subassemblyDescription,
          laptops: translations.subassemblyDescription,
          consoles: translations.subassemblyDescription,
          smartwatches: translations.subassemblyDescription,
        },
      },
      {
        icon: 'IconCheckInCircle',
        text: translations.trackpad,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/consoles/trackpad.webp',
          alt: 'Playstation 4',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.trackpadDescription,
          tablets: translations.trackpadDescription,
          laptops: translations.trackpadDescription,
          consoles: translations.trackpadDescription,
          smartwatches: translations.trackpadDescription,
        },
      },
      {
        icon: 'IconListView2',
        text: translations.features,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/consoles/imei_code_and_serial_number.webp',
          alt: 'Playstation 4',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.featuresDescription,
          tablets: translations.featuresDescription,
          laptops: translations.featuresDescription,
          consoles: translations.featuresDescription,
          smartwatches: translations.featuresDescription,
        },
      },
    ],

    image: {
      src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/categories/consoles.webp',
      alt: 'Console',
      height: 80,
      width: 80,
    },
  },
  laptops: {
    checkpoints: [
      {
        icon: 'IconTouch',
        text: translations.buttons,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/buttons.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.buttonsDescription,
          tablets: translations.buttonsDescription,
          laptops: translations.buttonsDescription,
          consoles: translations.buttonsDescription,
          smartwatches: translations.buttonsDescription,
        },
      },
      {
        icon: 'IconSim',
        text: translations.sim,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/sim_and_memory_card_reader.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.simDescriptionSmartphones,
          tablets: translations.simDescriptionTablets,
          laptops: translations.simDescriptionLaptops,
          consoles: translations.simDescriptionGamingConsoles,
          smartwatches: translations.simDescriptionSmartwatches,
        },
      },
      {
        icon: 'IconFactory',
        text: translations.factoryReset,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/factory_reset_completed.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.factoryResetDescription,
          tablets: translations.factoryResetDescription,
          laptops: translations.factoryResetDescription,
          consoles: translations.factoryResetDescription,
          smartwatches: translations.factoryResetDescription,
        },
      },
      {
        icon: 'IconSpinner',
        text: translations.configuration,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/configuration_software_completed.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.configurationDescription,
          tablets: translations.configurationDescription,
          laptops: translations.configurationDescription,
          consoles: translations.configurationDescription,
          smartwatches: translations.configurationDescription,
        },
      },
      {
        icon: 'IconPowerAdapterApple',
        text: translations.charger,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/accessories.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.chargerDescriptionSmartphones,
          tablets: translations.chargerDescriptionTablets,
          laptops: translations.chargerDescriptionLaptops,
          consoles: translations.chargerDescriptionGamingConsoles,
          smartwatches: translations.chargerDescriptionSmartwatches,
        },
      },
      {
        icon: 'IconLockUnlocked',
        text: translations.unlocked,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/unlocked_from_any_previous_protection_systems.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.unlockedDescription,
          tablets: translations.unlockedDescription,
          laptops: translations.unlockedDescription,
          consoles: translations.unlockedDescription,
          smartwatches: translations.unlockedDescription,
        },
      },
      {
        icon: 'IconLocationPinOutlined',
        text: translations.gps,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/gps_and_positioning_system.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.gpsDescription,
          tablets: translations.gpsDescription,
          laptops: translations.gpsDescription,
          consoles: translations.gpsDescription,
          smartwatches: translations.gpsDescription,
        },
      },
      {
        icon: 'IconCamera',
        text: translations.cameras,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/all_cameras.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.camerasDescription,
          tablets: translations.camerasDescription,
          laptops: translations.camerasDescription,
          consoles: translations.camerasDescription,
          smartwatches: translations.camerasDescription,
        },
      },
      {
        icon: 'IconTouch',
        text: translations.externalSensor,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/external_sensors.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.externalSensorDescription,
          tablets: translations.externalSensorDescription,
          laptops: translations.externalSensorDescription,
          consoles: translations.externalSensorDescription,
          smartwatches: translations.externalSensorDescription,
        },
      },
      {
        icon: 'IconCardView',
        text: translations.internalSensors,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/internal_sensors.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.internalSensorsDescription,
          tablets: translations.internalSensorsDescription,
          laptops: translations.internalSensorsDescription,
          consoles: translations.internalSensorsDescription,
          smartwatches: translations.internalSensorsDescription,
        },
      },
      {
        icon: 'IconCheckInCircle',
        text: translations.biometricSensors,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/biometric_sensors.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.biometricSensorsDescription,
          tablets: translations.biometricSensorsDescription,
          laptops: translations.biometricSensorsDescription,
          consoles: translations.biometricSensorsDescription,
          smartwatches: translations.biometricSensorsDescription,
        },
      },
      {
        icon: 'IconWifi',
        text: translations.wifi,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/wi-fi_and_bluetooth.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.wifiDescription,
          tablets: translations.wifiDescription,
          laptops: translations.wifiDescription,
          consoles: translations.wifiDescription,
          smartwatches: translations.wifiDescription,
        },
      },
      {
        icon: 'IconCrosshair',
        text: translations.imei,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/imei_code_and_serial_number.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.imeiDescription,
          tablets: translations.imeiDescription,
          laptops: translations.imeiDescription,
          consoles: translations.imeiDescription,
          smartwatches: translations.imeiDescription,
        },
      },
      {
        icon: 'IconWaterdrop',
        text: translations.oxidation,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/oxidation_indicator.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.oxidationDescription,
          tablets: translations.oxidationDescription,
          laptops: translations.oxidationDescription,
          consoles: translations.oxidationDescription,
          smartwatches: translations.oxidationDescription,
        },
      },
      {
        icon: 'IconHeadset',
        text: translations.microphone,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/mic_and_speakers.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.microphoneDescription,
          tablets: translations.microphoneDescription,
          laptops: translations.microphoneDescription,
          consoles: translations.microphoneDescription,
          smartwatches: translations.microphoneDescription,
        },
      },
      {
        icon: 'IconChargingWire',
        text: translations.ports,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/input-output_port_connections.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.portsDescription,
          tablets: translations.portsDescription,
          laptops: translations.portsDescription,
          consoles: translations.portsDescription,
          smartwatches: translations.portsDescription,
        },
      },
      {
        icon: 'IconBattery',
        text: translations.battery,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/battery_functionality.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.batteryDescription,
          tablets: translations.batteryDescription,
          laptops: translations.batteryDescription,
          consoles: translations.batteryDescription,
          smartwatches: translations.batteryDescription,
        },
      },
      {
        icon: 'IconRepairHome',
        text: translations.mechanical,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/mechanical_parts.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.mechanicalDescription,
          tablets: translations.mechanicalDescription,
          laptops: translations.mechanicalDescription,
          consoles: translations.mechanicalDescription,
          smartwatches: translations.mechanicalDescription,
        },
      },
      {
        icon: 'IconExport',
        text: translations.compatiblePart,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/compatible_parts.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.compatiblePartDescription,
          tablets: translations.compatiblePartDescription,
          laptops: translations.compatiblePartDescription,
          consoles: translations.compatiblePartDescription,
          smartwatches: translations.compatiblePartDescription,
        },
      },
      {
        icon: 'IconSmartphone',
        text: translations.screen,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/screen_quality.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.screenDescriptionSmartphones,
          tablets: translations.screenDescriptionTablets,
          laptops: translations.screenDescriptionLaptops,
          consoles: translations.screenDescriptionGamingConsoles,
          smartwatches: translations.screenDescriptionSmartwatches,
        },
      },
      {
        icon: 'IconPerformance',
        text: translations.cooling,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/cooling_elements.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.coolingDescription,
          tablets: translations.coolingDescription,
          laptops: translations.coolingDescription,
          consoles: translations.coolingDescription,
          smartwatches: translations.coolingDescription,
        },
      },
      {
        icon: 'IconSwap',
        text: translations.subassembly,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/sub-assembly_elements.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.subassemblyDescription,
          tablets: translations.subassemblyDescription,
          laptops: translations.subassemblyDescription,
          consoles: translations.subassemblyDescription,
          smartwatches: translations.subassemblyDescription,
        },
      },
      {
        icon: 'IconCheckInCircle',
        text: translations.trackpad,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/trackpad.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.trackpadDescription,
          tablets: translations.trackpadDescription,
          laptops: translations.trackpadDescription,
          consoles: translations.trackpadDescription,
          smartwatches: translations.trackpadDescription,
        },
      },
      {
        icon: 'IconListView2',
        text: translations.features,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/laptops/imei_code_and_serial_number.webp',
          alt: 'Macbook Pro',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.featuresDescription,
          tablets: translations.featuresDescription,
          laptops: translations.featuresDescription,
          consoles: translations.featuresDescription,
          smartwatches: translations.featuresDescription,
        },
      },
    ],

    image: {
      src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/categories/laptops.webp',
      alt: 'Laptop',
      height: 80,
      width: 80,
    },
  },
  smartwatches: {
    checkpoints: [
      {
        icon: 'IconTouch',
        text: translations.buttons,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartwatches/buttons.webp',
          alt: 'Apple watch',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.buttonsDescription,
          tablets: translations.buttonsDescription,
          laptops: translations.buttonsDescription,
          consoles: translations.buttonsDescription,
          smartwatches: translations.buttonsDescription,
        },
      },
      {
        icon: 'IconSim',
        text: translations.sim,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartwatches/sim_and_memory_card_reader.webp',
          alt: 'Apple watch',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.simDescriptionSmartphones,
          tablets: translations.simDescriptionTablets,
          laptops: translations.simDescriptionLaptops,
          consoles: translations.simDescriptionGamingConsoles,
          smartwatches: translations.simDescriptionSmartwatches,
        },
      },
      {
        icon: 'IconFactory',
        text: translations.factoryReset,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartwatches/factory_reset_completed.webp',
          alt: 'Apple watch',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.factoryResetDescription,
          tablets: translations.factoryResetDescription,
          laptops: translations.factoryResetDescription,
          consoles: translations.factoryResetDescription,
          smartwatches: translations.factoryResetDescription,
        },
      },
      {
        icon: 'IconPowerAdapterApple',
        text: translations.charger,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartwatches/accessories.webp',
          alt: 'Apple watch',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.chargerDescriptionSmartphones,
          tablets: translations.chargerDescriptionTablets,
          laptops: translations.chargerDescriptionLaptops,
          consoles: translations.chargerDescriptionGamingConsoles,
          smartwatches: translations.chargerDescriptionSmartwatches,
        },
      },
      {
        icon: 'IconLockUnlocked',
        text: translations.unlocked,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartwatches/unlocked_from_any_previous_protection_systems.webp',
          alt: 'Apple watch',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.unlockedDescription,
          tablets: translations.unlockedDescription,
          laptops: translations.unlockedDescription,
          consoles: translations.unlockedDescription,
          smartwatches: translations.unlockedDescription,
        },
      },
      {
        icon: 'IconLocationPinOutlined',
        text: translations.gps,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartwatches/gps_and_positioning_system.webp',
          alt: 'Apple watch',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.gpsDescription,
          tablets: translations.gpsDescription,
          laptops: translations.gpsDescription,
          consoles: translations.gpsDescription,
          smartwatches: translations.gpsDescription,
        },
      },
      {
        icon: 'IconTouch',
        text: translations.externalSensor,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartwatches/external_sensors.webp',
          alt: 'Apple watch',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.externalSensorDescription,
          tablets: translations.externalSensorDescription,
          laptops: translations.externalSensorDescription,
          consoles: translations.externalSensorDescription,
          smartwatches: translations.externalSensorDescription,
        },
      },
      {
        icon: 'IconCheckInCircle',
        text: translations.biometricSensors,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartwatches/biometric_sensors.webp',
          alt: 'Apple watch',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.biometricSensorsDescription,
          tablets: translations.biometricSensorsDescription,
          laptops: translations.biometricSensorsDescription,
          consoles: translations.biometricSensorsDescription,
          smartwatches: translations.biometricSensorsDescription,
        },
      },
      {
        icon: 'IconWifi',
        text: translations.wifi,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartwatches/wi-fi_and_bluetooth.webp',
          alt: 'Apple watch',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.wifiDescription,
          tablets: translations.wifiDescription,
          laptops: translations.wifiDescription,
          consoles: translations.wifiDescription,
          smartwatches: translations.wifiDescription,
        },
      },
      {
        icon: 'IconCrosshair',
        text: translations.imei,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartwatches/imei_code_and_serial_number.webp',
          alt: 'Apple watch',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.imeiDescription,
          tablets: translations.imeiDescription,
          laptops: translations.imeiDescription,
          consoles: translations.imeiDescription,
          smartwatches: translations.imeiDescription,
        },
      },
      {
        icon: 'IconWaterdrop',
        text: translations.oxidation,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartwatches/oxidation_indicator.webp',
          alt: 'Apple watch',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.oxidationDescription,
          tablets: translations.oxidationDescription,
          laptops: translations.oxidationDescription,
          consoles: translations.oxidationDescription,
          smartwatches: translations.oxidationDescription,
        },
      },
      {
        icon: 'IconHeadset',
        text: translations.microphone,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartwatches/mic_and_speakers.webp',
          alt: 'Apple watch',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.microphoneDescription,
          tablets: translations.microphoneDescription,
          laptops: translations.microphoneDescription,
          consoles: translations.microphoneDescription,
          smartwatches: translations.microphoneDescription,
        },
      },
      {
        icon: 'IconChargingWire',
        text: translations.ports,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartwatches/input-output_port_connections.webp',
          alt: 'Apple watch',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.portsDescription,
          tablets: translations.portsDescription,
          laptops: translations.portsDescription,
          consoles: translations.portsDescription,
          smartwatches: translations.portsDescription,
        },
      },
      {
        icon: 'IconWifi',
        text: translations.carrier,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartwatches/phone_carrier.webp',
          alt: 'Apple watch',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.carrierDescription,
          tablets: translations.carrierDescription,
          laptops: translations.carrierDescription,
          consoles: translations.carrierDescription,
          smartwatches: translations.carrierDescription,
        },
      },
      {
        icon: 'IconBattery',
        text: translations.battery,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartwatches/battery_functionality.webp',
          alt: 'Apple watch',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.batteryDescription,
          tablets: translations.batteryDescription,
          laptops: translations.batteryDescription,
          consoles: translations.batteryDescription,
          smartwatches: translations.batteryDescription,
        },
      },
      {
        icon: 'IconRepairHome',
        text: translations.mechanical,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartwatches/mechanical_parts.webp',
          alt: 'Apple watch',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.mechanicalDescription,
          tablets: translations.mechanicalDescription,
          laptops: translations.mechanicalDescription,
          consoles: translations.mechanicalDescription,
          smartwatches: translations.mechanicalDescription,
        },
      },
      {
        icon: 'IconExport',
        text: translations.compatiblePart,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartwatches/compatible_parts.webp',
          alt: 'Apple watch',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.compatiblePartDescription,
          tablets: translations.compatiblePartDescription,
          laptops: translations.compatiblePartDescription,
          consoles: translations.compatiblePartDescription,
          smartwatches: translations.compatiblePartDescription,
        },
      },
      {
        icon: 'IconSmartphone',
        text: translations.screen,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartwatches/screen_quality.webp',
          alt: 'Apple watch',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.screenDescriptionSmartphones,
          tablets: translations.screenDescriptionTablets,
          laptops: translations.screenDescriptionLaptops,
          consoles: translations.screenDescriptionGamingConsoles,
          smartwatches: translations.screenDescriptionSmartwatches,
        },
      },
      {
        icon: 'IconListView2',
        text: translations.features,
        image: {
          src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/checkpoints_v5/smartwatches/imei_code_and_serial_number.webp',
          alt: 'Apple watch',
          width: 1000,
          height: 1000,
        },
        description: {
          smartphones: translations.featuresDescription,
          tablets: translations.featuresDescription,
          laptops: translations.featuresDescription,
          consoles: translations.featuresDescription,
          smartwatches: translations.featuresDescription,
        },
      },
    ],

    image: {
      src: 'https://creative-pages.statics.backmarket.com/verified-refurbished/categories/smartwatches.webp',
      alt: 'Smartwatch',
      height: 80,
      width: 80,
    },
  },
} as const
