<template>
  <div
    class="flex flex-row items-center gap-8 overflow-x-auto md:justify-center"
  >
    <button
      v-for="option of categoryOptions"
      :key="option.category"
      class="relative flex cursor-pointer flex-col items-center justify-center gap-4"
      @click.stop="() => emit('category-selected', option.category)"
    >
      <div
        class="bg-action-default-mid rounded-sm h-80 w-80 border p-6 hover:border-static-default-hi"
        :class="
          option.category === selectedCategory
            ? 'border-static-default-hi'
            : 'border-static-default-low opacity-40'
        "
      >
        <img
          :alt="CATEGORIES_DATA[option.category].image.alt"
          :src="CATEGORIES_DATA[option.category].image.src"
        />
      </div>

      <div
        class="caption capitalize"
        :class="
          option.category === selectedCategory
            ? 'text-static-default-hi'
            : 'text-static-default-low'
        "
      >
        {{ option.label }}
      </div>
    </button>
  </div>
</template>

<script setup lang="ts">
import { categories as CATEGORIES_DATA } from './categories'
import type { AnimatedProductCategory } from './types'

defineProps<{
  categoryOptions: Array<{ label: string; category: AnimatedProductCategory }>
  selectedCategory: AnimatedProductCategory
}>()

const emit = defineEmits<{
  (e: 'category-selected', cat: AnimatedProductCategory): void
}>()
</script>
