<template>
  <div class="flex flex-col">
    <CategorySelector
      v-if="showCategoryList"
      :category-options
      class="mb-8"
      :selected-category
      @category-selected="defaultProgress"
    />
    <div
      class="bg-action-default-mid relative flex w-full grow overflow-hidden rounded-[32px]"
    >
      <div
        class="flex h-full w-full flex-col-reverse"
        :class="{ 'md:flex-row': !displayCheckpointsInRow }"
      >
        <div
          class="h-[13.625rem] w-full px-24 pb-32 pt-24"
          :class="{ 'md:hidden': !displayCheckpointsInRow }"
        >
          <div
            class="border-action-default-hi-disabled rounded-sm flex h-full w-full items-center justify-center border-1"
          >
            <p
              v-for="(item, index) in checkpoints"
              :key="index"
              class="text-static-default-hi text-caption w-full max-w-[512px] p-8 text-center"
              :class="addDescriptionAnimationClasses(index)"
            >
              {{ i18n(item.description[selectedCategory]) }}
            </p>
          </div>
        </div>
        <div
          ref="listWrapper"
          class="border-static-default-low relative w-full overflow-hidden border-r-1"
          :class="[
            displayCheckpointsInRow
              ? $style.listWrapperRow
              : $style.listWrapper,
            { 'md:h-full md:w-1/3': !displayCheckpointsInRow },
          ]"
          @touchend.prevent="onTouchend"
          @touchmove.prevent="onTouchmove"
          @touchstart.prevent="onTouchstart"
          @wheel.prevent="onWheel"
        >
          <button
            class="absolute left-1/2 top-20 z-10 hidden translate-x-[-50%] cursor-pointer flex-col items-center"
            :class="{ 'md:flex': !displayCheckpointsInRow }"
            @click="onClickScrollUp"
          >
            <div
              class="bg-surface-default-low rounded-full mb-4 flex h-40 w-40 items-center justify-center"
            >
              <IconChevronUp />
            </div>
            <p class="body-2 select-none">
              {{ i18n(scrollUp) }}
            </p>
          </button>
          <div
            :class="{
              'md:absolute md:left-1/2 md:w-full md:max-w-[22rem] md:translate-x-[-50%]':
                !displayCheckpointsInRow,
            }"
          >
            <ul
              ref="list"
              class="my-8 flex min-w-max flex-row flex-nowrap"
              :class="{
                'md:my-0 md:min-w-0 md:flex-col': !displayCheckpointsInRow,
              }"
            >
              <!-- eslint-disable-next-line -->
              <li
                v-for="(item, index) in infiniteCheckpoints"
                :key="index"
                :ref="index === 0 ? 'firstCheckpoint' : undefined"
                class="body-1 font-weight-body-1-bold mr-16 flex w-[9.25rem] cursor-pointer select-none flex-col items-center justify-center last:mb-0 last:mr-0"
                :class="[
                  addCheckpointClasses(index),
                  { 'md:mb-16 md:mr-0 md:w-auto': !displayCheckpointsInRow },
                ]"
                @click="onClickCheckpoint(index)"
              >
                <component :is="getIconComponent(item.icon)" />
                <div
                  class="mt-6 flex h-auto items-center justify-center"
                  :class="{ 'md:h-[2.5rem]': !displayCheckpointsInRow }"
                >
                  <p class="text-center">
                    {{ i18n(item.text) }}
                  </p>
                </div>
              </li>
            </ul>
          </div>
          <button
            class="absolute bottom-20 left-1/2 z-10 hidden translate-x-[-50%] cursor-pointer flex-col-reverse items-center"
            :class="{ 'md:flex': !displayCheckpointsInRow }"
            @click="onClickScrollDown"
          >
            <div
              class="bg-surface-default-low rounded-full mt-4 flex h-40 w-40 items-center justify-center"
            >
              <IconChevronDown />
            </div>
            <p class="body-2 select-none">
              {{ i18n(scrollDown) }}
            </p>
          </button>
        </div>
        <div
          class="relative flex w-full flex-1 flex-col"
          :class="{ 'md:h-full md:w-2/3': !displayCheckpointsInRow }"
        >
          <div class="relative flex-1">
            <template v-for="(item, index) in checkpoints" :key="index">
              <div
                v-if="item.image"
                ref="imagesRefs"
                class="absolute left-0 top-0 h-full w-full"
                :class="addImageAnimationClasses(index)"
              >
                <RevIllustration
                  :alt="item.image.alt"
                  class="absolute left-1/2 h-full w-auto max-w-none -translate-x-1/2"
                  :height="item.image.height"
                  :src="item.image.src"
                  :width="item.image.width"
                />
              </div>
            </template>
          </div>
          <div
            class="hidden w-full justify-center px-24 pb-32 pt-24"
            :class="{ 'md:flex': !displayCheckpointsInRow }"
          >
            <p
              v-for="(item, index) in checkpoints"
              :key="index"
              class="border-action-default-hi-disabled text-static-default-hi rounded-sm text-body-2 w-full max-w-[700px] border-1 p-8 text-center"
              :class="addDescriptionAnimationClasses(index)"
            >
              {{ i18n(item.description[selectedCategory]) }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue'

// FIXME: it does not make sense to import i18n types from a Nuxt module when we are not using Nuxt
import type { I18n } from '@backmarket/nuxt-module-i18n/types/i18n'
import type { EventData } from '@backmarket/nuxt-module-tracking/types'
import { useDangerouslyComputedBreakpoint } from '@backmarket/utils/composables/useDangerouslyComputedBreakpoint'
import { Breakpoint } from '@backmarket/utils/dom/getCurrentBreakpoint'
import { clamp } from '@backmarket/utils/math/clamp'
import { lerp } from '@backmarket/utils/math/lerp'
import { RevIllustration } from '@ds/components/Illustration'
import { IconChevronDown } from '@ds/icons/IconChevronDown'
import { IconChevronUp } from '@ds/icons/IconChevronUp'

import translations from './AnimatedProductCheckpoints.translations'
import { getIconComponent } from './AnimatedProductCheckpoints.utils'
import CategorySelector from './CategorySelector.vue'
import { categories as CATEGORIES_DATA } from './categories'
import type {
  AnimatedProductCategory,
  AnimatedProductCheckpointsVariant,
} from './types'
import { useAnimatedProductCheckpoints } from './useAnimatedProductCheckpoints'
import { useRaf } from './useRaf'

const { scrollDown, scrollUp } = translations

const props = defineProps<{
  categories: AnimatedProductCategory[]
  i18n: I18n
  trackClick: (data: EventData) => void
  trackingZone: string
  variant?: AnimatedProductCheckpointsVariant
}>()

const {
  selectedCategory,
  selectCategory,
  showCategoryList,
  targetProgress,
  categoryOptions,
} = useAnimatedProductCheckpoints(() => props.categories, props.i18n)

const checkpoints = computed(
  () => CATEGORIES_DATA[selectedCategory.value].checkpoints,
)

const checkpointsCount = computed(() => checkpoints.value.length)

const infiniteCheckpoints = computed(() => [
  ...checkpoints.value,
  ...checkpoints.value,
  ...checkpoints.value,
])

const displayCheckpointsInRow = computed(() => props.variant === 'row')

defineExpose({ checkpointsCount })

const progress = ref<number>(1)

const imagesRefs = ref<Array<HTMLElement>>([])

const listWrapper = ref<HTMLElement | null>(null)
const list = ref<HTMLElement | null>(null)

const hasInteracted = ref(false)

const windowSize = ref({
  width: 0,
  height: 0,
})

const nbCheckpointsPerClick = 1
const progressClickStep = computed(
  () =>
    Math.round(
      (1 / (checkpoints.value.length - 1)) * nbCheckpointsPerClick * 1000,
    ) / 1000,
)

let wrapperSize = 0
let checkpointSize = 0

const stepValue = 3 / infiniteCheckpoints.value.length

// drag
let isDragging = false
const dragResistance = 2000
const drag = {
  current: 0,
  distance: 0,
}

const lerpTolerance = 0.00001
const progressClickStepMultiplier = 1.00001

const magnetismThreshold = 0.001

const activeNormalizedCheckpointIndex = ref<number>(0)

const activeInfiniteCheckpointIndex = computed(() =>
  Math.round(progress.value * checkpoints.value.length),
)

/**
 *
 * Classes
 */

function addImageAnimationClasses(index: number) {
  return {
    'opacity-100': activeNormalizedCheckpointIndex.value === index,
    'opacity-0': activeNormalizedCheckpointIndex.value !== index,
  }
}

function addDescriptionAnimationClasses(index: number) {
  return {
    block: activeNormalizedCheckpointIndex.value === index,
    hidden: activeNormalizedCheckpointIndex.value !== index,
  }
}

function addCheckpointClasses(index: number) {
  return {
    'text-static-default-hi': activeInfiniteCheckpointIndex.value === index,
    'text-static-default-low': activeInfiniteCheckpointIndex.value !== index,
  }
}

/**
 *
 * Initial progress
 */
function setInitialProgress() {
  progress.value += stepValue
  targetProgress.value += stepValue
}

/**
 *
 * has interacted
 */
function setHasInteracted() {
  if (!hasInteracted.value) {
    hasInteracted.value = true

    if (props.trackClick && props.trackingZone) {
      props.trackClick({
        zone: props.trackingZone,
        name: 'check_list_scroll_block',
      })
    }
  }
}

function setWindowsValue() {
  const isRowVersion =
    useDangerouslyComputedBreakpoint().value < Breakpoint.MD ||
    displayCheckpointsInRow.value
  if (!listWrapper.value || !list.value) return
  wrapperSize = isRowVersion
    ? listWrapper.value.offsetWidth
    : listWrapper.value.offsetHeight
  checkpointSize = isRowVersion ? 148 : 70
}

/**
 *
 * Infinite scroll list
 */

function checkProgressLimits() {
  const offset = 0.0001

  if (targetProgress.value === 2) {
    targetProgress.value = 1 - offset
    progress.value = 1 - offset
  } else if (targetProgress.value === 1) {
    targetProgress.value += 1 + offset
    progress.value += 1 + offset
  }
}

function applyListTransform() {
  if (!checkpointSize || !wrapperSize) setWindowsValue()
  if (list.value) {
    const baseOffset = (checkpointSize + 16) * checkpoints.value.length * -1
    const normalizedProgress = progress.value - 1

    if (
      useDangerouslyComputedBreakpoint().value < Breakpoint.MD ||
      displayCheckpointsInRow.value
    ) {
      const containerSize = displayCheckpointsInRow.value
        ? wrapperSize / 2 - checkpointSize / 2 - 8
        : checkpointSize / 2 + 16
      list.value.style.transform = `translate3D(${baseOffset + normalizedProgress * baseOffset + containerSize}px, 0, 0)`
    } else {
      list.value.style.transform = `translate3D(0, ${baseOffset + normalizedProgress * baseOffset + wrapperSize / 2 - checkpointSize / 2 - 8}px, 0)`
    }
  }
}

/**
 *
 * Magnetism
 */

function setTargetProgressToClosestCheckpointStep() {
  const closestStep = Math.round(targetProgress.value / stepValue)
  const closestProgress = closestStep * stepValue

  if (targetProgress.value === closestProgress) return

  targetProgress.value = closestProgress
}

/**
 *
 * Events
 */

function onClickCheckpoint(index: number) {
  setHasInteracted()

  const indexDiff = index - activeInfiniteCheckpointIndex.value

  if (targetProgress.value + progressClickStep.value * indexDiff <= 1) {
    targetProgress.value += 1
    progress.value = targetProgress.value
  } else if (targetProgress.value + progressClickStep.value * indexDiff >= 2) {
    targetProgress.value -= 1
    progress.value = targetProgress.value
  }

  targetProgress.value = clamp(
    targetProgress.value + progressClickStep.value * indexDiff,
    1,
    2,
  )
}

function onClickScrollDown() {
  setHasInteracted()

  targetProgress.value = clamp(
    targetProgress.value +
      progressClickStep.value * progressClickStepMultiplier,
    1,
    2,
  )
}

function onClickScrollUp() {
  setHasInteracted()

  targetProgress.value = clamp(
    targetProgress.value -
      progressClickStep.value * progressClickStepMultiplier,
    1,
    2,
  )
}

function onResize() {
  windowSize.value.width = window.innerWidth
  windowSize.value.height = window.innerHeight
  setWindowsValue()
  applyListTransform()
}

function onWheel(event: WheelEvent) {
  if (useDangerouslyComputedBreakpoint().value < Breakpoint.MD) return

  setHasInteracted()

  const delta: number = clamp(
    displayCheckpointsInRow.value ? event.deltaX : event.deltaY,
    -30,
    30,
  )

  targetProgress.value = clamp(targetProgress.value + delta / 1000, 1, 2)
}

function onTouchstart(e: TouchEvent) {
  setHasInteracted()

  isDragging = true
  drag.current = e.touches[0].clientX
}

function onTouchmove(e: TouchEvent) {
  if (
    !isDragging ||
    e.touches.length === 0 ||
    !(useDangerouslyComputedBreakpoint().value < Breakpoint.MD)
  )
    return
  drag.distance = (e.touches[0].clientX - drag.current) * -1
  targetProgress.value = clamp(
    targetProgress.value + drag.distance / dragResistance,
    1,
    2,
  )

  drag.current = e.touches[0].clientX
}

function onTouchend() {
  isDragging = false
  drag.current = 0
  drag.distance = 0
}

function onTick() {
  const targetProgressDiff = Math.abs(targetProgress.value - progress.value)
  if (targetProgressDiff === 0) return

  if (targetProgressDiff < lerpTolerance) {
    progress.value = targetProgress.value
  } else {
    if (targetProgressDiff < magnetismThreshold && targetProgressDiff > 0) {
      setTargetProgressToClosestCheckpointStep()
    }

    progress.value = lerp(progress.value, targetProgress.value, 0.4)
    checkProgressLimits()
    applyListTransform()
  }
}

function defaultProgress(newCategory: AnimatedProductCategory) {
  selectCategory(newCategory)
  progress.value = 1
  setInitialProgress()
  applyListTransform()
}

/**
 *
 * Listeners
 */
function addEvents(): void {
  window.addEventListener('resize', onResize)
}

function removeEvents(): void {
  window.removeEventListener('resize', onResize)
}

onMounted(() => {
  addEvents()
  onResize()
  setInitialProgress()
  applyListTransform()
})

onUnmounted(() => {
  removeEvents()
})

watch(
  () => activeInfiniteCheckpointIndex.value,
  (index) => {
    const normalizedIndex = index % checkpoints.value.length
    if (checkpoints.value[normalizedIndex].image) {
      activeNormalizedCheckpointIndex.value = normalizedIndex
    }
  },
)

useRaf(onTick)
</script>

<style module>
.listWrapperRow:before,
.listWrapper:before {
  content: '';
  z-index: 2;
  pointer-events: none;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgb(236, 238, 242);
  background: linear-gradient(
    90deg,
    rgba(236, 238, 242, 1) 0%,
    rgba(236, 238, 242, 0) 49.81%,
    rgba(236, 238, 242, 1) 100%
  );
}
@media (min-width: theme('screens.md')) {
  .listWrapper:before {
    border-top-left-radius: 32px;
    border-bottom-left-radius: 32px;
    background: linear-gradient(
      180deg,
      rgba(236, 238, 242, 1) 17.5%,
      rgba(236, 238, 242, 0) 52.08%,
      rgba(236, 238, 242, 1) 83.36%
    );
  }
}
</style>
