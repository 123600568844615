import { type MaybeRefOrGetter, computed, ref, toValue } from 'vue'

import type { I18n } from '@backmarket/nuxt-module-i18n/types/i18n'

import translations from './AnimatedProductCheckpoints.translations'
import type { AnimatedProductCategory } from './types'

export function useAnimatedProductCheckpoints(
  categories: MaybeRefOrGetter<AnimatedProductCategory[]>,
  i18n: I18n,
) {
  const targetProgress = ref(1)

  const selectedCategory = ref(toValue(categories)[0])

  const showCategoryList = computed(() => toValue(categories).length > 1)

  function selectCategory(newCategory: AnimatedProductCategory) {
    selectedCategory.value = newCategory
    targetProgress.value = 1
  }

  const categoryOptions = computed(() =>
    toValue(categories).map((cat) => {
      const categoryTitleTranslationKey = `${cat}Title` as const

      const translationDefinition = translations[categoryTitleTranslationKey]

      if (!translationDefinition) {
        return {
          category: cat,
          label: cat,
        }
      }

      return {
        category: cat,
        label: i18n(translations[categoryTitleTranslationKey]),
      }
    }),
  )

  return {
    selectCategory,
    targetProgress,
    selectedCategory,
    showCategoryList,
    categoryOptions,
  }
}
