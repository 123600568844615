/**
 * Linear interpolation between two numbers.
 *
 * @param x - The start value.
 * @param y - The end value.
 * @param t - The interpolation value.
 * @return The interpolated value.
 */

export const lerp = (x: number, y: number, t: number): number => {
  return (1 - t) * x + t * y
}
