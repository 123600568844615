export default {
  scrollUp: {
    id: 'vr_checkpoints_scroll_up',
    defaultMessage: 'Scroll up',
  },
  scrollDown: {
    id: 'vr_checkpoints_scroll_down',
    defaultMessage: 'Scroll down',
  },

  // TODO: this was copy pasted from creative-pages app. We may need to find a way to harmonize this
  smartphonesTitle: {
    id: 'vr_smartphones_title',
    defaultMessage: 'smartphones',
  },
  laptopsTitle: {
    id: 'vr_laptops_title',
    defaultMessage: 'laptops',
  },
  tabletsTitle: {
    id: 'vr_tablets_title',
    defaultMessage: 'tablets',
  },
  consolesTitle: {
    id: 'vr_gaming_consoles_title',
    defaultMessage: 'gaming consoles',
  },
  smartwatchesTitle: {
    id: 'vr_smartwatches_title',
    defaultMessage: 'smartwatches',
  },

  /* Checkpoints */
  buttons: {
    id: 'vr_checkpoints_item_buttons',
    defaultMessage: 'Buttons',
  },
  buttonsDescription: {
    id: 'vr_checkpoints_item_buttons_description',
    defaultMessage:
      'This includes pressing the button and making sure that they open the right functions (volume, lock screen, etc.)',
  },
  sim: {
    id: 'vr_checkpoints_item_sim_reader',
    defaultMessage: 'SIM & Memory card reader',
  },
  simDescriptionSmartphones: {
    id: 'vr_checkpoints_item_sim_reader_description_smartphones',
    defaultMessage:
      'This tests that SIM cards and memory cards are correctly read by the device, and any trays or slots to insert or remove the card works properly.',
  },
  simDescriptionTablets: {
    id: 'vr_checkpoints_item_sim_reader_description_tablets',
    defaultMessage:
      'This tests that SIM cards and memory cards are correctly read by the device, and any trays or slots to insert or remove the card works properly.',
  },
  simDescriptionLaptops: {
    id: 'vr_checkpoints_item_sim_reader_description_laptops',
    defaultMessage:
      'This tests that SIM cards and memory cards are correctly read by the device, and any trays or slots to insert or remove the card works properly.',
  },
  simDescriptionGamingConsoles: {
    id: 'vr_checkpoints_item_sim_reader_description_gaming_consoles',
    defaultMessage:
      'This tests that SIM cards and memory cards are correctly read by the device, and any trays or slots to insert or remove the card works properly.',
  },
  simDescriptionSmartwatches: {
    id: 'vr_checkpoints_item_sim_reader_description_smartwatches',
    defaultMessage:
      'This tests that SIM cards and memory cards are correctly read by the device, and any trays or slots to insert or remove the card works properly.',
  },
  factoryReset: {
    id: 'vr_checkpoints_item_factory_reset',
    defaultMessage: 'Factory reset',
  },
  factoryResetDescription: {
    id: 'vr_checkpoints_item_factory_reset_description',
    defaultMessage:
      'Devices undergo a factory reset, which means that data is securely erased to original manufacturer settings.',
  },
  configuration: {
    id: 'vr_checkpoints_item_software_configuration',
    defaultMessage: 'Configuration software',
  },
  configurationDescription: {
    id: 'vr_checkpoints_item_software_configuration_description',
    defaultMessage:
      'This test ensures that the device has the most recent operating system installed.',
  },
  charger: {
    id: 'vr_checkpoints_item_charger_connectors_cables',
    defaultMessage: 'Matching charger, checked connectors and cables',
  },
  chargerDescriptionSmartphones: {
    id: 'vr_checkpoints_item_charger_connectors_cables_description_smartphones',
    defaultMessage:
      "Chargers, including the connector(s) and cord(s), are inspected for damage and that the plug is compatible your country's sockets. (Please note: Not all refurbished devices come with chargers).",
  },
  chargerDescriptionTablets: {
    id: 'vr_checkpoints_item_charger_connectors_cables_description_tablets',
    defaultMessage:
      "Chargers, including the connector(s) and cord(s), are inspected for damage and that the plug is compatible your country's sockets. (Please note: Not all refurbished devices come with chargers).",
  },
  chargerDescriptionLaptops: {
    id: 'vr_checkpoints_item_charger_connectors_cables_description_laptops',
    defaultMessage:
      "Chargers, including the connector(s) and cord(s), are inspected for damage and that the plug is compatible your country's sockets. (Please note: Not all refurbished devices come with chargers).",
  },
  chargerDescriptionGamingConsoles: {
    id: 'vr_checkpoints_item_charger_connectors_cables_description_gaming_consoles',
    defaultMessage:
      "Chargers, including the connector(s) and cord(s), are inspected for damage and that the plug is compatible your country's sockets. (Please note: Not all refurbished devices come with chargers).",
  },
  chargerDescriptionSmartwatches: {
    id: 'vr_checkpoints_item_charger_connectors_cables_description_smartwatches',
    defaultMessage:
      "Chargers, including the connector(s) and cord(s), are inspected for damage and that the plug is compatible your country's sockets. (Please note: Not all refurbished devices come with chargers).",
  },
  unlocked: {
    id: 'vr_checkpoints_item_unlocked_product',
    defaultMessage: 'Unlocked product',
  },
  unlockedDescription: {
    id: 'vr_checkpoints_item_unlocked_product_description',
    defaultMessage:
      'Refurbished devices are unlocked from any previous protection systems set by the previous owner, or third-party accounts or software (e.g. user accounts, passwords).',
  },
  gps: {
    id: 'vr_checkpoints_item_digital_gps',
    defaultMessage: 'GPS & Positioning system',
  },
  gpsDescription: {
    id: 'vr_checkpoints_item_digital_gps_description',
    defaultMessage:
      'This tests that the GPS and positioning system are accurate and working.',
  },
  cameras: {
    id: 'vr_checkpoints_item_cameras',
    defaultMessage: 'Cameras',
  },
  camerasDescription: {
    id: 'vr_checkpoints_item_cameras_description',
    defaultMessage:
      'All cameras are inspected for functionality, image capture and quality. This includes video conferencing.',
  },
  externalSensor: {
    id: 'vr_checkpoints_item_external_sensor',
    defaultMessage: 'External sensor',
  },
  externalSensorDescription: {
    id: 'vr_checkpoints_item_external_sensor_description',
    defaultMessage:
      'This includes checking that sensors for distance, camera aperture, brightness, laser measurement, and heartbeat are working correctly and accurately.',
  },
  internalSensors: {
    id: 'vr_checkpoints_item_internal_sensors',
    defaultMessage: 'Internal sensors',
  },
  internalSensorsDescription: {
    id: 'vr_checkpoints_item_internal_sensors_description',
    defaultMessage:
      'This tests that internal sensors, like temperature, are working correctly and accurately.',
  },
  biometricSensors: {
    id: 'vr_checkpoints_item_biometric_sensors',
    defaultMessage: 'Biometric sensors',
  },
  biometricSensorsDescription: {
    id: 'vr_checkpoints_item_biometric_sensors_description',
    defaultMessage:
      'This includes testing that Touch ID, Face ID, ID sensor, and voice recognition are working correctly and accurately.',
  },
  wifi: {
    id: 'vr_checkpoints_item_wifi_bluetooth',
    defaultMessage: 'Wi-Fi & Bluetooth',
  },
  wifiDescription: {
    id: 'vr_checkpoints_item_wifi_bluetooth_description',
    defaultMessage:
      'This tests that the WiFi and Bluetooth functions are working correctly and accurately.',
  },
  imei: {
    id: 'vr_checkpoints_item_imei',
    defaultMessage: 'IMEI & Serial number',
  },
  imeiDescription: {
    id: 'vr_checkpoints_item_imei_description',
    defaultMessage:
      'The IMEI serial number on a device is checked to see if it has been blacklisted and if the device configuration (like color and capacity) match manufacturer settings.',
  },
  oxidation: {
    id: 'vr_checkpoints_item_oxidation_indicator',
    defaultMessage: 'Oxidation indicator',
  },
  oxidationDescription: {
    id: 'vr_checkpoints_item_oxidation_indicator_description',
    defaultMessage:
      'This tests for water damage by inspecting the oxidation indicator within the device.',
  },
  flash: {
    id: 'vr_checkpoints_item_flash_indicator_lights',
    defaultMessage: 'Flash & indicator lights',
  },
  flashDescription: {
    id: 'vr_checkpoints_item_flash_indicator_lights_description',
    defaultMessage:
      'Refurbished devices are checked to ensure that the flash and other indicator lights work properly.',
  },
  microphone: {
    id: 'vr_checkpoints_item_microphone_speakers',
    defaultMessage: 'Microphone & speakers',
  },
  microphoneDescription: {
    id: 'vr_checkpoints_item_microphone_speakers_description',
    defaultMessage:
      'Speakers and microphones are inspected and calibrated so that they capture and play sound correctly.',
  },
  ports: {
    id: 'vr_checkpoints_item_input_output_port',
    defaultMessage: 'Input/output port',
  },
  portsDescription: {
    id: 'vr_checkpoints_item_input_output_port_description',
    defaultMessage:
      'Ports, like those used for headphone jacks, USB, ethernet, are inspected for functionality.',
  },
  carrier: {
    id: 'vr_checkpoints_item_phone_carrier',
    defaultMessage: 'Phone carrier',
  },
  carrierDescription: {
    id: 'vr_checkpoints_item_phone_carrier_description',
    defaultMessage:
      'Refurbished devices are checked to determine if they are locked or unlocked to a phone carrier.',
  },
  battery: {
    id: 'vr_checkpoints_item_battery',
    defaultMessage: 'Battery',
  },
  batteryDescription: {
    id: 'vr_checkpoints_item_battery_description',
    defaultMessage:
      'Battery health is assessed to meet battery health and capacity requirements. Batteries that do not meet requirements will be replaced.',
  },
  mechanical: {
    id: 'vr_checkpoints_item_original_mechanical_parts',
    defaultMessage: 'Original mechanical parts',
  },
  mechanicalDescription: {
    id: 'vr_checkpoints_item_original_mechanical_parts_description',
    defaultMessage:
      'Mechanical parts like screws, case, frames, and others are check are genuine or compatible parts. If compatible parts are found, they are tested to ensure that they function as well as genuine parts.',
  },
  consumableItems: {
    id: 'vr_checkpoints_item_consumable_items',
    defaultMessage: 'Consumable items',
  },
  consumableItemsDescription: {
    id: 'vr_checkpoints_item_consumable_items_description',
    defaultMessage:
      'If accessories come with a refurbished device, they are inspected to ensure that they work properly.',
  },
  cooling: {
    id: 'vr_checkpoints_item_cooling_elements',
    defaultMessage: 'Cooling elements',
  },
  coolingDescription: {
    id: 'vr_checkpoints_item_cooling_elements_description',
    defaultMessage:
      'Cooling components like fans and vents are cleaned and inspected.',
  },
  screen: {
    id: 'vr_checkpoints_identical_screen_quality',
    defaultMessage: 'Identical screen quality',
  },
  screenDescriptionSmartphones: {
    id: 'vr_checkpoints_identical_screen_quality_description_smartphones',
    defaultMessage:
      'On-screen visuals are tested to ensure that there are no defects like stains, dusts, and discoloration from burn-in.',
  },
  screenDescriptionTablets: {
    id: 'vr_checkpoints_identical_screen_quality_description_tablets',
    defaultMessage:
      'On-screen visuals are tested to ensure that there are no defects like stains, dusts, and discoloration from burn-in.',
  },
  screenDescriptionLaptops: {
    id: 'vr_checkpoints_identical_screen_quality_description_laptops',
    defaultMessage:
      'On-screen visuals are tested to ensure that there are no defects like stains, dusts, and discoloration from burn-in.',
  },
  screenDescriptionGamingConsoles: {
    id: 'vr_checkpoints_identical_screen_quality_description_gaming_consoles',
    defaultMessage:
      'On-screen visuals are tested to ensure that there are no defects like stains, dusts, and discoloration from burn-in.',
  },
  screenDescriptionSmartwatches: {
    id: 'vr_checkpoints_identical_screen_quality_description_smartwatches',
    defaultMessage:
      'On-screen visuals are tested to ensure that there are no defects like stains, dusts, and discoloration from burn-in.',
  },
  subassembly: {
    id: 'vr_checkpoints_item_sub_assembly_impact_configuration',
    defaultMessage: 'Sub-assembly impacting the configuration',
  },
  subassemblyDescription: {
    id: 'vr_checkpoints_item_sub_assembly_impact_configuration_description',
    defaultMessage:
      'Sub-assembInternal components like the hard disk, SSD, graphics card are inspected to ensure that all parts are present and fully functional.ly impacting the configuration',
  },
  trackpad: {
    id: 'vr_checkpoints_item_trackpad_keyboard_touch_bar_backlighting',
    defaultMessage: 'Trackpad, keyboard, touch bar, and backlighting',
  },
  trackpadDescription: {
    id: 'vr_checkpoints_item_trackpad_keyboard_touch_bar_backlighting_description',
    defaultMessage:
      'Trackpads, touch bars, keyboards are tested and checked for full functionality. This can also include inspecting that backlighting works properly.',
  },
  features: {
    id: 'vr_checkpoints_item_other_features',
    defaultMessage: 'Other features',
  },
  featuresDescription: {
    id: 'vr_checkpoints_item_other_features_description',
    defaultMessage: 'Other features specific to this device are checked',
  },
  compatiblePart: {
    id: 'vr_checkpoints_item_compatible_part',
    defaultMessage:
      'Compatible part must have the same performance as the original ones',
  },
  compatiblePartDescription: {
    id: 'vr_checkpoints_item_compatible_part_description',
    defaultMessage:
      'If compatible parts are used during refurbishment, these parts are tested to ensure that they have the same performance as genuine parts.',
  },
} as const
