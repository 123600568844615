import { onMounted, onUnmounted } from 'vue'

export function useRaf(onTick: (delta: number) => void) {
  let requestId: number | null = null
  let lastTime = performance.now()

  const tick = (time: number) => {
    const delta = time - lastTime
    lastTime = time
    onTick(delta)
    requestId = requestAnimationFrame(tick)
  }

  onMounted(() => {
    lastTime = performance.now()
    requestId = requestAnimationFrame(tick)
  })

  onUnmounted(() => {
    if (requestId !== null) {
      cancelAnimationFrame(requestId)
    }
  })
}
