<template>
  <div>
    <BlockTitle :subtitle :title />
    <div class="flex w-full flex-col">
      <!-- prefixing this component with "Base" prevents name conflicts and allows to stub it in the test -->
      <BaseAnimatedProductCheckpoints
        :categories="categoriesNames"
        class="h-[680px]"
        :i18n
        :trackClick
        trackingZone=""
      />

      <div v-if="button" class="mt-24 flex w-full justify-center">
        <CmsButton
          data-test="checkpoints-cta-button"
          v-bind="button"
          full-width="fixed"
          :tracking
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import type { AnimatedProductCheckpointsProps } from '@backmarket/http-api/src/api-specs-content/models/animated-product-checkpoints'
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { useTracking } from '@backmarket/nuxt-module-tracking/useTracking'

import type { ContentBlockProps } from '../../models/content-block'
import BaseAnimatedProductCheckpoints from '../../shared-components/AnimatedProductCheckpoints/AnimatedProductCheckpoints.vue'
import BlockTitle from '../../shared-components/BlockTitle/BlockTitle.vue'
import CmsButton from '../Button/Button.vue'

const props = defineProps<AnimatedProductCheckpointsProps & ContentBlockProps>()

// This event is common to every CMS block in order to hide the block containers
// on demand
defineEmits(['error'])

const i18n = useI18n()
const { trackClick } = useTracking()

const categoriesNames = props.categories.map((category) => category.name)
</script>
