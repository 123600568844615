import { IconBattery } from '@ds/icons/IconBattery'
import { IconCamera } from '@ds/icons/IconCamera'
import { IconCardView } from '@ds/icons/IconCardView'
import { IconChargingWire } from '@ds/icons/IconChargingWire'
import { IconCheckInCircle } from '@ds/icons/IconCheckInCircle'
import { IconCrosshair } from '@ds/icons/IconCrosshair'
import { IconExport } from '@ds/icons/IconExport'
import { IconFactory } from '@ds/icons/IconFactory'
import { IconHeadset } from '@ds/icons/IconHeadset'
import { IconLightbulb } from '@ds/icons/IconLightbulb'
import { IconListView2 } from '@ds/icons/IconListView2'
import { IconLocationPinOutlined } from '@ds/icons/IconLocationPinOutlined'
import { IconLockUnlocked } from '@ds/icons/IconLockUnlocked'
import { IconPerformance } from '@ds/icons/IconPerformance'
import { IconPowerAdapterApple } from '@ds/icons/IconPowerAdapterApple'
import { IconRepairHome } from '@ds/icons/IconRepairHome'
import { IconRepairMailIn } from '@ds/icons/IconRepairMailIn'
import { IconSim } from '@ds/icons/IconSim'
import { IconSmartphone } from '@ds/icons/IconSmartphone'
import { IconSpinner } from '@ds/icons/IconSpinner'
import { IconSwap } from '@ds/icons/IconSwap'
import { IconTouch } from '@ds/icons/IconTouch'
import { IconTrash } from '@ds/icons/IconTrash'
import { IconWaterdrop } from '@ds/icons/IconWaterdrop'
import { IconWifi } from '@ds/icons/IconWifi'

export function getIconComponent(iconName: string) {
  switch (iconName) {
    case 'IconBattery':
      return IconBattery
    case 'IconCamera':
      return IconCamera
    case 'IconCardView':
      return IconCardView
    case 'IconChargingWire':
      return IconChargingWire
    case 'IconCheckInCircle':
      return IconCheckInCircle
    case 'IconCrosshair':
      return IconCrosshair
    case 'IconExport':
      return IconExport
    case 'IconFactory':
      return IconFactory
    case 'IconHeadset':
      return IconHeadset
    case 'IconLightbulb':
      return IconLightbulb
    case 'IconListView2':
      return IconListView2
    case 'IconLocationPinOutlined':
      return IconLocationPinOutlined
    case 'IconLockUnlocked':
      return IconLockUnlocked
    case 'IconPerformance':
      return IconPerformance
    case 'IconPowerAdapterApple':
      return IconPowerAdapterApple
    case 'IconRepairHome':
      return IconRepairHome
    case 'IconRepairMailIn':
      return IconRepairMailIn
    case 'IconSim':
      return IconSim
    case 'IconSmartphone':
      return IconSmartphone
    case 'IconSpinner':
      return IconSpinner
    case 'IconSwap':
      return IconSwap
    case 'IconTouch':
      return IconTouch
    case 'IconTrash':
      return IconTrash
    case 'IconWaterdrop':
      return IconWaterdrop
    case 'IconWifi':
      return IconWifi
    default:
      return null
  }
}
